<template>
  <div class="row">
    
    <div
      v-can="'access_financial_accounts'"
      class="col-xl-3 col-xxl-3 col-sm-6"
    >
      <router-link :to="{ name: 'Financial-Accounts-List' }">
        <div class="widget-stat card bg-white">
          <div class="card-body red-border-right">
            <div class="media">
              <span class="mr-3">
                <i class="la la-gear"></i>
              </span>
              <div class="media-body text-white">
                <h3 class="text-blue">Accounts</h3>
              </div>
            </div>
          </div>
        </div>
      </router-link>
    </div>

    <div
      v-can="'access_financial_headings'"
      class="col-xl-3 col-xxl-3 col-sm-6"
    >
      <router-link :to="{ name: 'Financial-Headings-List' }">
        <div class="widget-stat card bg-white">
          <div class="card-body red-border-right">
            <div class="media">
              <span class="mr-3">
                <i class="la la-gear"></i>
              </span>
              <div class="media-body text-white">
                <h3 class="text-blue">Headings</h3>
              </div>
            </div>
          </div>
        </div>
      </router-link>
    </div>

    <div
      v-can="'access_financial_transactions'"
      class="col-xl-3 col-xxl-3 col-sm-6"
    >
      <router-link :to="{ name: 'Financial-Transactions-List' }">
        <div class="widget-stat card bg-white">
          <div class="card-body red-border-right">
            <div class="media">
              <span class="mr-3">
                <i class="la la-gear"></i>
              </span>
              <div class="media-body text-white">
                <h3 class="text-blue">Transactions</h3>
              </div>
            </div>
          </div>
        </div>
      </router-link>
    </div>

    <div
      v-can="'bank_statement_financial_reports'"
      class="col-xl-3 col-xxl-3 col-sm-6"
    >
      <router-link :to="{ name: 'Financial-Reports-Bank-Statement' }">
        <div class="widget-stat card bg-white">
          <div class="card-body red-border-right">
            <div class="media">
              <span class="mr-3">
                <i class="la la-gear"></i>
              </span>
              <div class="media-body text-white">
                <h3 class="text-blue">Bank Statement</h3>
              </div>
            </div>
          </div>
        </div>
      </router-link>
    </div>

    <div
      v-can="'installments_financial_reports'"
      class="col-xl-3 col-xxl-3 col-sm-6"
    >
      <router-link :to="{ name: 'Financial-Reports-Installments' }">
        <div class="widget-stat card bg-white">
          <div class="card-body red-border-right">
            <div class="media">
              <span class="mr-3">
                <i class="la la-gear"></i>
              </span>
              <div class="media-body text-white">
                <h3 class="text-blue">Installments Report</h3>
              </div>
            </div>
          </div>
        </div>
      </router-link>
    </div>


  </div>
</template>